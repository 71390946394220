<template>
    <v-flex>
        <v-container fluid>
            <v-layout fill-height row wrap justify-center align-center>
                <v-flex mx-12 lg-12 md-12>
                    <!-- 绿色框 -->
                    <v-alert :dismissible="false" class="blue white--text" dark border="left" >
                        <span> Trading Record </span>
                    </v-alert>
                    <v-flex>
                        <v-data-table :headers="headers" :items="tradingRecord" hide-default-footer class="elevation-1" >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-btn @click="symbol='btc'">BTC</v-btn>
                                    <v-btn @click="symbol='eth'">ETH</v-btn>
                                    <v-btn @click="symbol='bch'">BCH</v-btn>
                                    <v-btn @click="symbol='ltc'">LTC</v-btn>
                                    <v-btn @click="symbol='xrp'">XRP</v-btn>
                                    <v-btn @click="getTradingRecord(symbol)">测试按钮</v-btn>
                                </v-toolbar>
                            </template>
                            <!-- 表数据 -->
                            <template v-slot:body="{ user }">
                                <tbody>
                                    <tr v-for="(record,index) in tradingRecord" :key="record.uid">
                                        <td>{{ common.formatTime(Number(record.timestamp)) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-container>
    </v-flex>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers:[
                    {text:"Time" },
                    {text:"Long/Buy" },
                    {text:"Short/Sell" },
                    {text:"Entry Price" },
                    {text:"Exit Price" },
                    {text:"avg. entry price" },
                    {text:"Position" },
                    {text:"Position P/L" },
                    {text:"Realized P/L" },
                ],
                symbol:'eth',
                tradingRecord:[],
                pageNo:2,
                okexFuturesData:{Okex期货:"Okex期货"},
                category:"spot",
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
           
        },
        methods: {
            getTradingRecord(symbol){
                let api = config.baseUrl + '/exchange/kraken/futures/private/getTradingRecord?symbol='+ symbol +'&pageNo=' + this.pageNo;
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    console.log(data)
                    if(data.code == 200){
                        this.tradingRecord = data.result;
                    }
                })
            },
           
        }
    }
</script>

<style>

</style>